import type { GatsbyBrowser } from "gatsby"

import wrapWithProvider from "./wrap-with-provider"

export const wrapRootElement = wrapWithProvider

export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({ routerProps, prevRouterProps }) => {
    const shouldPreserveScrollPosition =
        prevRouterProps && prevRouterProps.location.pathname === routerProps.location.pathname

    return !shouldPreserveScrollPosition // If false is returned it makes gatsby preserve scroll position on route or url search params change
}
